import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`🔩 Usage`}</h1>
    <p>{`Getting ready to run a `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{`? Awesome! Here's a framework for thinking about running conversations, and a checklist.`}</p>
    <h2><strong parentName="h2">{`Setting up your conversation`}</strong></h2>
    <ol>
      <li parentName="ol">{``}<a parentName="li" {...{
          "href": "/Log-in",
          "title": "Log in"
        }}>{`Log in`}</a>{``}</li>
      <li parentName="ol">{`Head to `}<a parentName="li" {...{
          "href": "/conversation-configuration",
          "title": "conversation configuration"
        }}>{`conversation configuration`}</a>{` in the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{``}</li>
      <li parentName="ol">{`Choose a `}<a parentName="li" {...{
          "href": "/topic",
          "title": "topic"
        }}>{`topic`}</a>{``}</li>
      <li parentName="ol">{`Decide how you'll handle `}<a parentName="li" {...{
          "href": "/Moderation",
          "title": "👹 Moderation"
        }}>{`👹 Moderation`}</a>{``}</li>
      <li parentName="ol">{`Decide how you'll handle `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{` `}<a parentName="li" {...{
          "href": "/Identity",
          "title": "👩‍🎤 Identity"
        }}>{`👩‍🎤 Identity`}</a>{``}</li>
      <li parentName="ol">{`Head to `}<a parentName="li" {...{
          "href": "/distribute",
          "title": "distribute"
        }}>{`distribute`}</a>{` and grab the `}<a parentName="li" {...{
          "href": "/embed-code",
          "title": "embed code"
        }}>{`embed code`}</a>{`, see: `}<a parentName="li" {...{
          "href": "/Embedding-Polis-on-Your-Web-Property",
          "title": "Embedding Polis on Your Web Property"
        }}>{`Embedding Polis on Your Web Property`}</a>{``}</li>
      <li parentName="ol">{`Decide whether to turn the visualization on or off: see `}<a parentName="li" {...{
          "href": "/example-scenarios-and-their-configurations",
          "title": "example scenarios and their configurations"
        }}>{`example scenarios and their configurations`}</a>{``}</li>
      <li parentName="ol">{`Consider `}<a parentName="li" {...{
          "href": "/educating-participants",
          "title": "educating participants"
        }}>{`educating participants`}</a>{` about the issue at hand`}</li>
      <li parentName="ol">{`Consider `}<a parentName="li" {...{
          "href": "/Roles",
          "title": "👷🏾👷 Roles"
        }}>{`👷🏾👷 Roles`}</a>{` and who will fill them`}</li>
    </ol>
    <h2><strong parentName="h2">{`Distributing and marketing your conversation`}</strong></h2>
    <p>{`Who needs to be invited?
How will you invite them?
Organizations have found that putting a minimal (low hundreds of dollars) amount of advertising spend towards either a general population in a location (everyone in San Francisco) or to relevant populations (healthcare workers) has been effective at generating cost-effective 500-2000 person conversations, though this does not ensure a `}<a parentName="p" {...{
        "href": "/representative-sample",
        "title": "representative sample"
      }}>{`representative sample`}</a>{``}</p>
    <h2><strong parentName="h2">{`Planning the duration of your conversation`}</strong></h2>
    <p>{`If all `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` are in the same place at the same time, a conversation can be run in 10 to 15 minutes. This is less common. More often, conversations are run asynchronously over a period of 1-3 weeks. You should communicate to `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` that they will need to regularly revisit the `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` to `}<a parentName="p" {...{
        "href": "/vote",
        "title": "vote"
      }}>{`vote`}</a>{` on `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{` that have been added since they last voted. Consider `}<a parentName="p" {...{
        "href": "/closing-commenting-early",
        "title": "closing commenting early"
      }}>{`closing commenting early`}</a>{``}</p>
    <h2><strong parentName="h2">{`Monitoring your conversation`}</strong></h2>
    <p>{`Use the real-time `}<a parentName="p" {...{
        "href": "/monitor",
        "title": "monitor"
      }}>{`monitor`}</a>{` and `}<a parentName="p" {...{
        "href": "/report",
        "title": "report"
      }}>{`report`}</a>{``}</p>
    <h2><strong parentName="h2">{`Handling reasonable concerns`}</strong></h2>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/gaming-the-system",
        "title": "gaming the system"
      }}>{`gaming the system`}</a>{`, `}<a parentName="p" {...{
        "href": "/trolling",
        "title": "trolling"
      }}>{`trolling`}</a>{``}</p>
    <h2><strong parentName="h2">{`Framing and context`}</strong></h2>
    <p>{`If there is a formal decision-making process to which `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` results will provide input, see `}<a parentName="p" {...{
        "href": "/facilitation",
        "title": "facilitation"
      }}>{`facilitation`}</a>{``}</p>
    <h2><strong parentName="h2">{`After the conversation`}</strong></h2>
    <ul>
      <li parentName="ul">{`Examine the `}<a parentName="li" {...{
          "href": "/report",
          "title": "report"
        }}>{`report`}</a>{``}</li>
      <li parentName="ul">{`Get the full dataset from data `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{``}</li>
      <li parentName="ul">{`Learn about the `}<a parentName="li" {...{
          "href": "/ultimate-value-delivery",
          "title": "ultimate value delivery"
        }}>{`ultimate value delivery`}</a>{` of your conversation (see: `}<a parentName="li" {...{
          "href": "/services",
          "title": "services"
        }}>{`services`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      